<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }}
              {{ this.table.search == "" && this.territory_id == null ? ' of ' + this.table.total : ' of ' + this.table.totalSearch + ' (filtered from ' + this.table.total + ' total entries)' }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th>Action</vs-th>
        <vs-th sort-key="external_code">External Code</vs-th>
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="level">Level</vs-th>
        <vs-th sort-key="territory">Territory</vs-th>
        <vs-th sort-key="territory_area">Territory Area</vs-th>
        <vs-th sort-key="warehouse">Warehouse</vs-th>
        <!-- <vs-th sort-key="external_code">External Code</vs-th> -->
        <!-- <vs-th sort-key="created_at">Created On</vs-th>
        <vs-th sort-key="created_by">Created By</vs-th>
        <vs-th sort-key="updated_at">Updated On</vs-th>
        <vs-th sort-key="updated_by">Updated By</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                v-if="hasPermission('view')"
                @click.stop="handleTreeView(indextr)"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-eye"
                title="Tree View"
                style="margin-right: 5px"
                />
              <vs-button
                v-if="hasPermission('edit')"
                @click.stop="handleEdit(indextr)"
                size="small"
                color="warning"
                icon-pack="feather"
                icon="icon-edit"
                title="Edit"
                style="margin-right: 5px"
              />
              <vs-button
                v-if="hasPermission('edit')"
                @click.stop="handleDelete(indextr)"
                size="small"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
                title="Delete"
                style="margin-right: 5px"
              />
            </div>
          </vs-td>
          <vs-td :data="data[indextr].external_code">
            {{ data[indextr].external_code }}
          </vs-td>
          <vs-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </vs-td>
          <vs-td :data="data[indextr].level">
            {{ data[indextr].level }}
          </vs-td>
          <vs-td :data="data[indextr].territory_id">
            {{ data[indextr].territory_code + " " + data[indextr].territory }}
          </vs-td>
          <vs-td :data="data[indextr].territory_area_id">
            {{ data[indextr].territory_area }}
          </vs-td>
          <vs-td :data="data[indextr].warehouse_id">
            {{ data[indextr].warehouse }}
          </vs-td>
          <!-- <vs-td :data="data[indextr].external_code">
            {{ data[indextr].external_code }}
          </vs-td> -->
          <!-- <vs-td :data="data[indextr].created_at">
            {{ data[indextr].created_at }}
          </vs-td>
          <vs-td :data="data[indextr].created_by">
            {{ data[indextr].created_by }}
          </vs-td>
          <vs-td :data="data[indextr].updated_at">
            {{ data[indextr].updated_at | formatDate }}
          </vs-td>
          <vs-td :data="data[indextr].updated_by">
            {{ data[indextr].updated_by }}
          </vs-td> -->
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import internal from "stream";
import moment from "moment";

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    detail: {
      type: Boolean,
    },
    flag: {
      type: Number,
    },
    territory_id: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      itemDel: 0,
    };
  },
  watch: {
    detail() {
      if(this.detail == false){
        this.getData();
      }
    },
    territory_id() {
      this.getData(this.territory_id);
    },
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "master-work-team");
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.handleChangePage(1)
      this.getData(this.territory_id);
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData(this.territory_id);
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData(this.territory_id);
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData(this.territory_id);
    },
    handleClose() {
      this.getData(this.territory_id);
      this.$emit("close");
    },
    handleCreate() {
      this.customerId = null;
      this.detail = true;
    },
    getData(territory_id) {
      if (territory_id == null) {
        territory_id = -1;
      }
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            flag: this.flag,
            territory_id: territory_id,
          },
        })
        .then((resp) => {
          // console.log(resp)
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    setStartEnd() {
      let valStart = this.table.length * this.table.page - this.table.length + 1;

      if (this.table.total == 0 && this.table.totalSearch == 0) {
        valStart = 0;
      }

      if (this.table.total > 0 && this.table.totalSearch == 0 && this.table.search != "") {
        valStart = 0;
      }

      let valEnd = this.table.length * this.table.page;

      if ( this.table.search != "" || this.territory_id != null && this.table.totalSearch < valEnd) {
        valEnd = this.table.totalSearch;
      }

      if (valEnd > this.table.total) {
        valEnd = this.table.total
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(i) {
      this.itemDel = i;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$http
        .delete(this.baseUrl + "/delete", {
          params: {
            work_team_id: this.table.data[this.itemDel].id,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        }).catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(index) {
      this.$router.push({
        name: "work-team-edit",
        query: {
          work_team_id: this.table.data[index].id,
          company_id: this.$store.state.user.currentUser.company_id,
        },
      });
    },
    handleTreeView(index) {
      console.log("handleTreeView")
      console.log("this.table.data[index].id", this.table.data[index].id)
      // this.$emit("close");
      this.$emit("detail", this.table.data[index].id);
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY,hh:mm:ss");
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
