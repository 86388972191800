<template>
  <vx-card title="Work Team">
    <!-- <div class="vx-input-group flex">
      <vs-button
        class="mt-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
    </div> -->

    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/6">
        <div class="vx-input-group flex">
          <vs-button
            v-if="hasPermission('create')"
            color="success"
            type="border"
            icon-pack="feather"
            icon="icon-plus"
            @click="handleCreate()"
            style="width: 100%;"
            >Create</vs-button
          >
        </div>
      </div>
      <div class="vx-col sm:w-1/3">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="200"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableTerritory"
        />
      </div>
      <div class="vx-col sm:w-3/6"></div>
    </div>

    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <data-table
          @close="handleClose"
          @detail="handleOpenTreeview"
          :baseUrl="this.baseUrl"
          :territory_id="this.selectedTerritory.id"
        ></data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
          </vs-row>

            <tree-view :source="'index'" :detail="detail" :id_workteam="this.selectedId"></tree-view>
            <!-- treeview -->
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import TreeView from './Tree_view.vue'
// import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTable,
    TreeView,
    // Datepicker,
  },
  mounted(){
    this.$store.dispatch("user/getPermissions", "master-work-team");
    this.getTerritory();
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      selectedId: null,
      baseUrl: "/api/sfa/v1/work-team",
      optionTerritory: [],
      selectedTerritory: { id: null, code: null, name: null },
    };
  },
  watch: {
    selectedTerritory: function() {
      console.log("Berubah lho!!")
      console.log("this.selectedTerritory", this.selectedTerritory)
    },
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "work-team-create",
      });
    },
    handleClose() {
      console.log("handleClose");
      this.detail = false;
    },
    handleOpenTreeview(id) {
      console.log("handleOpenTreeview, idnya?", id);
      this.selectedId = id;
      this.detail = true;
      console.log("this.selectedId = id;?", this.selectedId);
    },
    customLableTerritory ({ id, code, name }) {
      if (id == null && name != null && code != null) {
        return `${name}`
      } else if (id == null && name == null && code == null) {
        return `Select Territory`
      } else {
        return `${code} - ${name}`
      }
    },
    async getTerritory (ta_id = -1) {
      await this.$http
        .get('/api/sfa/v1/work-team/territory', {
          params: {
            territory_area_id: ta_id
          }
        })
        .then(resp => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              this.optionTerritory.push({ id: null, code: "", name: "All" });
              console.log("this.optionTerritory", this.optionTerritory)
            }
          } else {
            // console.log(resp)
            this.$vs.loading.close()
          }
        })
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
